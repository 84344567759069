<template>
 <div class="page-container">
	 <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
	 <div class="page-desc">
	   该报表统计所有借出待归还的资产
	 </div>  
	 <el-form ref="form" :inline="true"  :model="search_key" prop="" >
		 <div  style="position: absolute;right:20px;z-index: 999;">
		 	<el-button  type="primary" @click="dialogExportVisible=true">导出数据</el-button> 
		 </div>
	     <el-form-item label="">
	         <el-input v-model="search_key.keyword" placeholder="请输入资产编号搜索" style="width:200px;"></el-input>
	     </el-form-item>
		 <el-form-item label="">
			 <el-checkbox-group v-model="search_key.expire_status">
			   <el-checkbox :label="1">已逾期</el-checkbox>
			   <el-checkbox :label="2">未逾期</el-checkbox>
			 </el-checkbox-group>
		 </el-form-item>
	     <el-button type="primary" @click="search()">查询</el-button>
	 </el-form>
	 <!-- 导出弹窗 -->
	   <el-dialog title="导出筛选" :visible.sync="dialogExportVisible" width="30%">
	       <el-form   prop="" label-position="top">
	           <el-form-item label="借出时间">
	               <el-date-picker
	                   v-model="search_key.lend_time"
	                   type="datetimerange"
	                   range-separator="至"
	                   start-placeholder="开始日期"
	                   end-placeholder="结束日期"
	                   format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
	               </el-date-picker>
	           </el-form-item>
			   <el-form-item label="预计归还时间">
			       <el-date-picker
			           v-model="search_key.return_time"
			           type="datetimerange"
			           range-separator="至"
			           start-placeholder="开始日期"
			           end-placeholder="结束日期"
			           format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
			       </el-date-picker>
			   </el-form-item>
			    <el-form-item label="逾期状态" >
					 <el-checkbox-group v-model="search_key.expire_status2">
					   <el-checkbox :label="1">已逾期</el-checkbox>
					   <el-checkbox :label="2">未逾期</el-checkbox>
					 </el-checkbox-group>
				 </el-form-item>
	       </el-form>
	   <div slot="footer" class="dialog-footer">
	       <el-button type="primary" @click="handleExport()">导出</el-button>
	   </div>
	   </el-dialog>
	  <el-table
	     :data="tableData" 
	     border
		:row-style="{height:'20px'}">
		</el-table-column>
		<el-table-column
		    prop="asset_code"
		    label="资产编码">
			<template slot-scope="scope">
				<span>{{scope.row.content.asset_code}}</span>
			</template>
		</el-table-column>
	     <el-table-column
	         prop=""
	         label="借出时间">
			 <template slot-scope="scope">
			 	<span>{{scope.row.storage_content.lend_time}}</span>
			 </template>
	     </el-table-column>
	      <el-table-column
	         prop=""
	         label="预计归还时间">
			 <template slot-scope="scope">
			 	<span>{{scope.row.storage_content.return_time}}</span>
			 </template>
	     </el-table-column>
		 <el-table-column
		 	prop="expire_status"
		     label="逾期状态">
			 <template slot-scope="scope">
				<span>{{scope.row.expire_status ==1 ?'已逾期':'未逾期'}}</span>
			 </template>
		 </el-table-column>
	     <el-table-column 
	         label="资产名称">
			 <template slot-scope="scope">
			 	<span>{{scope.row.content.name}}</span>
			 </template>
	     </el-table-column>
		<el-table-column
		    label="资产分类">
				 <template slot-scope="scope">
					<span>{{scope.row.content.cate_name}}</span>
				 </template>
		</el-table-column>
		<el-table-column
		    label="借出单号">
				 <template slot-scope="scope">
					<span>{{scope.row.storage_content.receipt_number}}</span>
				 </template>
		</el-table-column>
		<el-table-column
		    label="借用人">
				 <template slot-scope="scope">
					<span>{{scope.row.storage_content.user_name}}</span>
				 </template>
		</el-table-column>
		<el-table-column
		    label="借用部门">
				 <template slot-scope="scope">
					<span>{{scope.row.storage_content.dept_name}}</span>
				 </template>
		</el-table-column>
	  </el-table>
		<pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view> 
		
		<CommonAddVue ref="commoAddDialog"></CommonAddVue>
	</div>
</template>

<script>
import CommonAddVue from "../Common/Add.vue"
export default {
	 inject:['reload'],
	 components: {
	 	CommonAddVue
	 },
    data() {
        return {
			dialogExportVisible:false,
			tableData:[],
			search_key:{
				keyword:'',
				lend_time:'',
				return_time:'',
				expire_status:[],
				expire_status2:[]
			},
			multipleData:[],			
			currentPage:1,
			pagesize:10,
			total:0,
        }
    },
    created() {
		this.getData()
    },
    mounted() {

    },
    methods:{
		getData(){  
		    this.$api.post('AssetReport/waitReturn',{
				keyword:this.search_key.keyword,
				expire_status:this.search_key.expire_status,
				page:this.currentPage-1,
				limit:this.pagesize,
			},res=>{
		        if(res.code == 200){
		             this.tableData =  res.data.list
					this.total =  res.data.count
		        }else{
		            this.$message.error(res.msg)
		        }
		    })
		},
		handleExport(){
			location.href = '/api/admin/AssetReport/exportWaitReturn?lend_time='+this.search_key.lend_time+'&return_time='+this.search_key.return_time+'&expire_tim='+this.search_key.expire_status2
		},

		search(){
			this.currentPage = 1
			this.getData()
		},
		getCurrPage(val){   //获取当前页
		    this.currentPage =  val
		    this.getData()
		},
		getPageSize(val){   //获取每页条数
		    this.pagesize =  val
		     this.getData()
		},	
    },
}
</script>
<style scoped lang="scss">
</style>